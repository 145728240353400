import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    effect,
    ElementRef,
    input,
    InputSignal,
    OnDestroy,
    OnInit,
    output,
    OutputEmitterRef,
    ResourceRef,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Select2, Select2Data } from 'ng-select2-component';
import { lastValueFrom, Subscription } from 'rxjs';

import { TenantResponse } from '../../../../domain/api-result';
import { LanguageService } from '../../../../services/language.service';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';

export interface Step1FormValues {
    address: string;
    latitude: number;
    longitude: number;
    bill: number;
    equipment: number;
}

@Component({
    selector: 'app-step-1',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        GoogleMapsModule,
        Select2,
        TranslateModule,
        LoadingComponent,
    ],
    templateUrl: './step-1.component.html',
    styleUrl: './step-1.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Step1Component implements OnInit, AfterViewInit, OnDestroy {
    public tenantResource: InputSignal<
        ResourceRef<TenantResponse | undefined>
    > = input.required();

    @ViewChild('autocompleteInput', { static: true })
    private autocompleteInput!: ElementRef<HTMLInputElement>;

    protected step1Form: FormGroup = new FormGroup({
        address: new FormControl(null, Validators.required),
        latitude: new FormControl(null, [
            Validators.required,
            Validators.min(-90),
            Validators.max(90),
        ]),
        longitude: new FormControl(null, [
            Validators.required,
            Validators.min(-180),
            Validators.max(180),
        ]),
        bill: new FormControl(null, [Validators.required, Validators.min(0)]),
        // commented and left because this field is dynamically added based on
        // company api response
        // equipment: new FormControl(null, Validators.required),
    });

    protected equipmentOptions: WritableSignal<Select2Data> = signal([
        {
            label: 'Photovoltaic panels',
            value: 1,
        },
        {
            label: 'Photovoltaic panels + battery',
            value: 2,
        },
    ]);

    private subscription: Subscription = new Subscription();

    public submitEvent: OutputEmitterRef<Step1FormValues> =
        output<Step1FormValues>();

    constructor(
        private readonly router: Router,
        private readonly languageService: LanguageService,
        private readonly translateService: TranslateService,
    ) {
        effect((): void => {
            const tenant: TenantResponse | undefined =
                this.tenantResource().value();

            if (this.tenantResource().isLoading()) {
                return;
            }

            if (undefined === tenant) {
                this.router
                    .navigate(['404'], {
                        skipLocationChange: true,
                        replaceUrl: false,
                    })
                    .then((): void => {});
                return;
            }

            this.languageService.setLanguageOptions(tenant);
            this.loadTranslatedSelectOptions();

            if (
                !tenant.has_battery_in_storage &&
                this.step1Form.contains('equipment')
            ) {
                this.step1Form.removeControl('equipment');
                return;
            } else if (
                tenant.has_battery_in_storage &&
                !this.step1Form.contains('equipment')
            ) {
                this.step1Form.addControl(
                    'equipment',
                    new FormControl('1', [Validators.required]),
                );
            }

            this.step1Form.updateValueAndValidity();
        });
    }

    public ngOnInit(): void {
        this.subscription.add(
            this.step1Form.get('address')?.valueChanges.subscribe((): void => {
                if (null !== this.step1Form.get('latitude')?.value) {
                    this.step1Form.get('latitude')?.setValue(null);
                }
                if (null !== this.step1Form.get('longitude')?.value) {
                    this.step1Form.get('longitude')?.setValue(null);
                }
            }),
        );
    }

    private loadTranslatedSelectOptions(): void {
        lastValueFrom(
            this.translateService.get([
                'Photovoltaic panels',
                'Photovoltaic panels + battery',
            ]),
        ).then((value: { [key: string]: string }): void => {
            this.equipmentOptions.set([
                {
                    label:
                        value['Photovoltaic panels'] ?? 'Photovoltaic panels',
                    value: 1,
                },
                {
                    label:
                        value['Photovoltaic panels + battery'] ??
                        'Photovoltaic panels + battery',
                    value: 2,
                },
            ]);
        });
    }

    public ngAfterViewInit(): void {
        this.getPlaceAutocomplete().then((): null => null);
    }

    private async getPlaceAutocomplete(): Promise<void> {
        const placesLibrary: google.maps.PlacesLibrary =
            (await google.maps.importLibrary(
                'places',
            )) as google.maps.PlacesLibrary;

        const autocomplete: google.maps.places.Autocomplete =
            new placesLibrary.Autocomplete(
                this.autocompleteInput.nativeElement,
                {
                    types: ['address'],
                    fields: ['geometry', 'formatted_address'],
                },
            );

        /**
         * The listener below is currently untestable for some reason
         * best I can do is triggering this event manually via
         *      google.maps.event.trigger(autocomplete, 'place_changed')
         * but then `autocomplete.getPlace() returns undefined
         * and providing input with the correct address does not change output
         */
        google.maps.event.addListener(autocomplete, 'place_changed', (): void =>
            this.setValueFromAutocomplete(autocomplete),
        );
    }

    protected setValueFromAutocomplete(
        autocomplete: google.maps.places.Autocomplete,
    ): void {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        this.address?.setValue(place.formatted_address);

        this.latitude?.setValue(place.geometry?.location?.lat());
        this.longitude?.setValue(place.geometry?.location?.lng());
    }

    protected get address(): AbstractControl<string | undefined> | null {
        return this.step1Form.get('address');
    }

    protected get latitude(): AbstractControl<number | undefined> | null {
        return this.step1Form.get('latitude');
    }

    protected get longitude(): AbstractControl<number | undefined> | null {
        return this.step1Form.get('longitude');
    }

    protected get bill(): AbstractControl<number | undefined> | null {
        return this.step1Form.get('bill');
    }

    protected get equipment(): AbstractControl<number | undefined> | null {
        return this.step1Form.get('equipment');
    }

    protected formSubmit(): void {
        this.step1Form.markAsTouched();
        this.address?.markAsTouched();
        this.bill?.markAsTouched();
        this.equipment?.markAsTouched();

        if (this.step1Form.invalid) {
            if (this.latitude?.invalid) {
                this.address?.setErrors(this.latitude.errors);
            }

            if (this.longitude?.invalid) {
                this.address?.setErrors(this.longitude.errors);
            }

            return;
        }

        this.submitEvent.emit(this.step1Form.value);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
