import {
    ChangeDetectionStrategy,
    Component,
    input,
    InputSignal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-aside-footer',
    standalone: true,
    imports: [TranslateModule],
    templateUrl: './aside-footer.component.html',
    styleUrl: './aside-footer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideFooterComponent {
    public isInSidebar: InputSignal<boolean> = input(false);
}
