import {
    ChangeDetectionStrategy,
    Component,
    input,
    InputSignal,
    model,
    ModelSignal,
    OnDestroy,
    output,
    OutputEmitterRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-toggle',
    standalone: true,
    imports: [],
    templateUrl: './toggle.component.html',
    styleUrl: './toggle.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent implements OnDestroy {
    public active: ModelSignal<boolean> = model(false);

    public isReadonly: InputSignal<boolean> = input(false);

    private subscription: Subscription = new Subscription();

    public toggleEvent: OutputEmitterRef<boolean> = output<boolean>();

    protected toggleActive(): void {
        if (this.isReadonly()) {
            return;
        }

        this.active.set(!this.active());

        this.toggleEvent.emit(this.active());
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
