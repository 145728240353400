import {
    ChangeDetectionStrategy,
    Component,
    effect,
    OnDestroy,
    ResourceLoaderParams,
    ResourceRef,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { rxResource, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
    firstValueFrom,
    interval,
    map,
    mergeMap,
    Observable,
    Subscription,
    takeWhile,
    throwError,
} from 'rxjs';

import { environment } from '../../../environments/environment';
import { fadeInOutAnimation } from '../../domain/animations';
import {
    AnalyzeResponse,
    JobStatusResponse,
    ModulesResponse,
    TenantResponse,
} from '../../domain/api-result';
import { ConfigService } from '../../services/config.service';
import { LanguageService } from '../../services/language.service';
import { SunnaApiService } from '../../services/sunna-api.service';
import { AddressErrorModalComponent } from '../../shared/components/address-error-modal/address-error-modal.component';
import { ModalService } from '../results/services/modal.service';
import { ModulesService } from '../results/services/modules.service';
import { CalculationsAsideComponent } from './components/calculations-aside/calculations-aside.component';
import { ColorsService } from './services/colors.service';
import { StepsService } from './services/steps.service';

@Component({
    selector: 'app-calculations',
    standalone: true,
    imports: [CalculationsAsideComponent, AddressErrorModalComponent],
    templateUrl: './calculations.component.html',
    styleUrl: './calculations.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fadeInOutAnimation,
})
export class CalculationsComponent implements OnDestroy {
    protected calculationStep: WritableSignal<number> = signal(1);
    protected intervalTime: number = 1000;
    protected jobId: string | undefined;

    private params: Signal<Params | undefined> = toSignal(
        this.activatedRoute.params,
    );
    private queryParams: Signal<Params | undefined> = toSignal(
        this.activatedRoute.queryParams,
    );
    private calculationSteps: string[] = [
        'scanning detecting edges',
        'checking sunlight',
        'detecting obstacles',
        'accounting for the distance from the edge',
        'fitting panels',
        'calculation of energy yields',
        'generating visualisation',
    ];
    protected calculationId: string | undefined;
    private sunnaSlug: string | undefined;

    private subscription: Subscription = new Subscription();

    protected tenantResource: ResourceRef<TenantResponse | undefined> =
        rxResource({
            request: this.params,
            loader: (
                params: ResourceLoaderParams<Params | undefined>,
            ): Observable<TenantResponse> => {
                return this.sunnaApiService.tenant(
                    params.request?.['companySlug'] ??
                        environment.DEFAULT_COMPANY_SLUG,
                );
            },
        });

    private analyzeResource: ResourceRef<AnalyzeResponse | undefined> =
        rxResource({
            request: this.params,
            loader: (
                params: ResourceLoaderParams<Params | undefined>,
            ): Observable<AnalyzeResponse> => {
                const queryParams: Params = this.queryParams() ?? {};

                this.sunnaApiService.prepareParameters({
                    address: queryParams['address'],
                    bill: queryParams['bill'],
                    latitude: queryParams['latitude'],
                    longitude: queryParams['longitude'],
                    email: queryParams['email'],
                    equipment: queryParams['equipment'],
                    firstName: queryParams['firstName'],
                    lastName: queryParams['lastName'],
                });

                return this.sunnaApiService.analyze(
                    params.request?.['companySlug'],
                );
            },
        });

    protected batteryModulesResource: ResourceRef<ModulesResponse | undefined> =
        rxResource({
            request: this.params,
            loader: (
                params: ResourceLoaderParams<Params | undefined>,
            ): Observable<ModulesResponse> => {
                return this.sunnaApiService.modulesFromCategory(
                    params.request?.['companySlug'],
                    'battery',
                );
            },
        });

    constructor(
        public readonly stepsService: StepsService,
        public readonly colorsService: ColorsService,
        private readonly sunnaApiService: SunnaApiService,
        private readonly modulesService: ModulesService,
        private readonly modalService: ModalService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        readonly languageService: LanguageService,
        readonly configService: ConfigService,
    ) {
        effect((): void => {
            this.queryParams();
            this.analyzeResource.reload();
        });

        effect((): void => {
            this.stepsService.calculationStep.set(this.calculationStep());
        });

        effect((): void => {
            const tenant: TenantResponse | undefined =
                this.tenantResource.value();
            const analyze: AnalyzeResponse | undefined =
                this.analyzeResource.value();

            if (
                this.tenantResource.isLoading() ||
                this.analyzeResource.isLoading()
            ) {
                return;
            }

            if (undefined === tenant) {
                this.router
                    .navigate(['404'], {
                        skipLocationChange: true,
                        replaceUrl: false,
                    })
                    .then((): void => {});
                return;
            }

            if (undefined === analyze) {
                this.modalService.setAddressErrorModalState(true);
                return;
            }

            languageService.setLanguageOptions(tenant);
            configService.setColorsFromTenant(tenant);

            this.sunnaSlug = tenant.slug;

            this.jobId = analyze.jobId;
            this.calculationId = analyze.calculationId;
            this.enableCheck();
        });
    }

    /**
     * creates interval that every tick requests checkJobStatus
     */
    protected createInterval(): Observable<string> {
        if (undefined === this.jobId) {
            return throwError((): Error => new Error('Missing jobId'));
        }

        const jobId: string = this.jobId;

        return interval(this.intervalTime).pipe(
            mergeMap(
                (): Observable<string> =>
                    this.sunnaApiService
                        .checkJobStatus(jobId)
                        .pipe(
                            map((res: JobStatusResponse): string => res.status),
                        ),
            ),
        );
    }

    private async setDefaultBattery(): Promise<void> {
        const modules: ModulesResponse | undefined =
            this.batteryModulesResource.value();

        if (
            undefined === modules ||
            undefined === this.calculationId ||
            1 > modules.count
        ) {
            return;
        }

        await firstValueFrom(
            this.sunnaApiService.saveCalculation(this.calculationId, {
                has_battery: true,
                system_battery_qty: 1,
                system_battery_module: modules.results[0].id,
            }),
        );
    }

    private async goToResults(): Promise<void> {
        const queryParams: Params = this.queryParams() ?? {};

        if ('2' === queryParams['equipment']) {
            await this.setDefaultBattery();
        }

        this.router
            .navigate([this.sunnaSlug, 'results'], {
                queryParams: {
                    calculationId: this.calculationId ?? '',
                },
            })
            .then((): void => {});
    }

    /**
     * subscribes to an interval when jobId is defined
     * then checks status from response
     * if status is different from "done"
     *      checks calculationSteps array for step index and updates animation
     * if status is "done"
     *      emits calculation ended Event and unsubscribes (`takeWhile`)
     */
    protected enableCheck(): void {
        if (undefined === this.jobId) {
            this.modalService.setAddressErrorModalState(true);
            return;
        }

        this.subscription.add(
            this.createInterval()
                .pipe(
                    takeWhile((status: string): boolean => {
                        return 'done' !== status;
                    }, true),
                )
                .subscribe({
                    next: (status: string): void => {
                        if ('done' === status) {
                            this.goToResults().then((): void => {});
                            return;
                        }

                        const index: number = this.calculationSteps.findIndex(
                            (stepStatus: string): boolean =>
                                stepStatus === status,
                        );

                        if (-1 !== index) {
                            this.calculationStep.set(index + 1);
                        } else {
                            this.calculationStep.set(1);
                        }
                    },
                    error: (): void => {
                        this.modalService.setAddressErrorModalState(true);
                    },
                }),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.tenantResource.destroy();
        this.analyzeResource.destroy();
    }
}
