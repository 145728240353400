import { CurrencyPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    input,
    InputSignal,
    OnDestroy,
    OnInit,
    signal,
    WritableSignal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { TenantResponse } from '../../../../domain/api-result';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';
import { InnerBoxComponent } from '../../components/inner-box/inner-box.component';
import { PriceComponent } from '../../components/price/price.component';
import { TabListComponent } from '../../components/tab-list/tab-list.component';
import {
    CalculatePricesService,
    CalculationResultData,
} from '../../services/calculate-prices.service';

@Component({
    selector: 'app-offer',
    standalone: true,
    imports: [
        CurrencyPipe,
        InnerBoxComponent,
        TranslateModule,
        TabListComponent,
        LoadingComponent,
        PriceComponent,
    ],
    templateUrl: './offer.component.html',
    styleUrl: './offer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferComponent implements OnInit, OnDestroy {
    public isReadonly: InputSignal<boolean> = input(false);
    public isLoading: InputSignal<boolean> = input(false);
    public tenant: InputSignal<TenantResponse | undefined> = input();

    protected prices: WritableSignal<CalculationResultData> = signal({
        basePrice: 0,
        batteryPrice: 0,
        grantPrice: 0,
        inverterPrice: 0,
        panelPrice: 0,
        systemPower: 0,
        systemPrice: 0,
        totalBatteryPrice: 0,
        totalPrice: 0,
        totalPricePerWp: 0,
    });

    private subscription: Subscription = new Subscription();

    constructor(
        private readonly calculatePricesService: CalculatePricesService,
    ) {}

    public ngOnInit(): void {
        this.subscription.add(
            this.calculatePricesService
                .calculationSub()
                .subscribe((data: CalculationResultData): void => {
                    this.prices.set(data);
                }),
        );

        this.calculatePricesService.forceRecalculate(undefined);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
