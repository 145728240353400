import { Component } from '@angular/core';

import { NotFoundAsideComponent } from './components/not-found-aside/not-found-aside.component';

@Component({
    selector: 'app-not-found',
    imports: [NotFoundAsideComponent],
    templateUrl: './not-found.component.html',
    styleUrl: './not-found.component.scss',
})
export class NotFoundComponent {}
