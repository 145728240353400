import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    signal,
    WritableSignal,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ModalComponent } from '../../../../shared/components/modal/modal.component';
import { ModalData, ModalService } from '../../services/modal.service';

@Component({
    selector: 'app-calculation-share-modal',
    standalone: true,
    imports: [ModalComponent, ReactiveFormsModule, TranslateModule],
    templateUrl: './calculation-share-modal.component.html',
    styleUrl: './calculation-share-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculationShareModalComponent implements OnDestroy {
    protected isOpen: WritableSignal<boolean> = signal(false);
    protected url: WritableSignal<string | undefined> = signal(undefined);

    private subscription: Subscription = new Subscription();

    constructor(
        private readonly modalService: ModalService,
        private readonly router: Router,
    ) {
        this.subscription.add(
            this.modalService.calculationShareModal.subscribe(
                (value: ModalData): void => {
                    if (!value.open) {
                        this.isOpen.set(false);
                        return;
                    }

                    const data: {
                        sunnaSlug: string | undefined;
                        calculationId: string | undefined;
                    } = value.data as {
                        sunnaSlug: string | undefined;
                        calculationId: string | undefined;
                    };

                    if (
                        undefined === data.sunnaSlug ||
                        undefined === data.calculationId
                    ) {
                        console.error('Missing sunnaSlug or calculationId');
                        return;
                    }

                    this.isOpen.set(value.open);

                    this.url.set(
                        window.location.origin +
                            this.router
                                .createUrlTree([
                                    data.sunnaSlug,
                                    'calculation',
                                    data.calculationId,
                                ])
                                .toString(),
                    );
                },
            ),
        );
    }

    protected close(): void {
        this.url.set(undefined);
        this.modalService.setCalculationShareModalState(false);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
