import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    input,
    InputSignal,
    model,
    ModelSignal,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
    Select2,
    Select2Data,
    Select2UpdateEvent,
    Select2UpdateValue,
    Select2Value,
} from 'ng-select2-component';
import { Select2Option } from 'ng-select2-component/lib/select2-interfaces';

import { Module, TenantResponse } from '../../../../domain/api-result';

interface Select2DataObject {
    [key: string]: Select2Option;
}

@Component({
    selector: 'app-components-select',
    standalone: true,
    imports: [TranslateModule, Select2],
    templateUrl: './components-select.component.html',
    styleUrl: './components-select.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentsSelectComponent {
    public modules: InputSignal<Module[] | undefined> = input();
    public tenant: InputSignal<TenantResponse | undefined> = input();
    public isReadonly: InputSignal<boolean> = input(false);

    public solarPanel: ModelSignal<Select2Option | undefined> =
        model.required();
    public inverter: ModelSignal<Select2Option | undefined> = model.required();

    protected solarPanelOptions: WritableSignal<Select2DataObject> = signal({});
    protected inverterOptions: WritableSignal<Select2DataObject> = signal({});

    protected solarPanelValue: WritableSignal<Select2UpdateValue> =
        signal(undefined);
    protected inverterValue: WritableSignal<Select2UpdateValue> =
        signal(undefined);

    protected solarPanelSelect2Data: Signal<Select2Data> = computed(
        (): Select2Data => {
            return Object.values(this.solarPanelOptions());
        },
    );

    protected inverterSelect2Data: Signal<Select2Data> = computed(
        (): Select2Data => {
            return Object.values(this.inverterOptions());
        },
    );

    constructor() {
        effect((): void => {
            const modules: Module[] | undefined = this.modules();

            this.solarPanelOptions.set({});
            this.inverterOptions.set({});

            if (undefined === modules || 0 === modules.length) {
                return;
            }

            const solarPanelOptions: { [key: string]: Select2Option } = {};
            const inverterOptions: { [key: string]: Select2Option } = {};

            modules.forEach((module: Module): void => {
                if ('panel' === module.category) {
                    solarPanelOptions[module.id] = {
                        label: this.getModuleLabel(module),
                        data: module.name,
                        value: module.id,
                    };
                } else if ('inverter' === module.category) {
                    inverterOptions[module.id] = {
                        label: this.getModuleLabel(module),
                        data: module.name,
                        value: module.id,
                    };
                }
            });

            this.solarPanelOptions.set(solarPanelOptions);
            this.inverterOptions.set(inverterOptions);

            if (
                0 < this.solarPanelSelect2Data().length ||
                undefined === this.solarPanel()
            ) {
                const value: Select2Value = (
                    this.solarPanelSelect2Data()[0] as Select2Option
                ).value;

                this.solarPanelUpdate({
                    value: value,
                } as Select2UpdateEvent);
            }

            if (
                0 < this.inverterSelect2Data().length ||
                undefined === this.inverter()
            ) {
                const value: Select2Value = (
                    this.inverterSelect2Data()[0] as Select2Option
                ).value;

                this.inverterUpdate({
                    value: value,
                } as Select2UpdateEvent);
            }
        });
    }

    private getModuleLabel(module: Module): string {
        return `${module.name} <span class="badge badge-success">+${module.price} ${this.tenant()?.currency_code ?? 'USD'} / 1Wp</span>`;
    }

    protected solarPanelUpdate(event: Select2UpdateEvent): void {
        if ('string' !== typeof event.value) {
            return;
        }

        const option: Select2Option | undefined =
            this.solarPanelOptions()?.[event.value];

        if (!option) {
            return;
        }

        this.solarPanelValue.set(option.value);
        this.solarPanel.set(option);
    }

    protected inverterUpdate(event: Select2UpdateEvent): void {
        if ('string' !== typeof event.value) {
            return;
        }

        const option: Select2Option | undefined =
            this.inverterOptions()?.[event.value];

        if (!option) {
            return;
        }

        this.inverterValue.set(option.value);
        this.inverter.set(option);
    }
}
