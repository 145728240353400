import {
    ChangeDetectionStrategy,
    Component,
    effect,
    ElementRef,
    OnDestroy,
    signal,
    WritableSignal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import {
    ActiveTabService,
    FinancingType,
} from '../../../../services/active-tab.service';
import { ConfigService } from '../../../../services/config.service';

@Component({
    selector: 'app-tab-list',
    standalone: true,
    imports: [TranslateModule],
    templateUrl: './tab-list.component.html',
    styleUrl: './tab-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabListComponent implements OnDestroy {
    protected activeTab: WritableSignal<FinancingType> = signal('cash');

    private subscription: Subscription = new Subscription();

    constructor(
        private readonly activeTabService: ActiveTabService,
        private readonly elementRef: ElementRef,
        private readonly configService: ConfigService,
    ) {
        effect((): void => {
            const color: string = this.configService.mainColor();
            this.elementRef.nativeElement.style.setProperty(
                '--bg-color',
                color,
            );
        });

        effect((): void => {
            this.activeTabService.setActiveTab(this.activeTab());
        });

        this.subscription.add(
            this.activeTabService.activeTab.subscribe(
                (activeTab: FinancingType): void => {
                    this.activeTab.set(activeTab);
                },
            ),
        );
    }

    protected changeActiveTab(tab: FinancingType): void {
        this.activeTab.set(tab);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
