import { computed, Injectable, Signal } from '@angular/core';

import {
    ColorComponents,
    ConfigService,
} from '../../../services/config.service';

@Injectable({
    providedIn: 'root',
})
export class ColorsService {
    constructor(private readonly configService: ConfigService) {}

    public darkerMainColor: Signal<string> = computed((): string => {
        const rgb: ColorComponents = this.configService.hex2rgb(
            this.mainColor(),
        );

        rgb.r = rgb.r - 20;
        rgb.g = rgb.g - 20;
        rgb.b = rgb.b - 20;

        if (rgb.r < 0) {
            rgb.r = 0;
        }

        if (rgb.g < 0) {
            rgb.g = 0;
        }

        if (rgb.b < 0) {
            rgb.b = 0;
        }

        return this.configService.rgb2hex(rgb);
    });

    public mainColor: Signal<string> = computed((): string => {
        return this.configService.mainColor();
    });

    public lighter1MainColor: Signal<string> = computed((): string => {
        const rgb: ColorComponents = this.configService.hex2rgb(
            this.mainColor(),
        );

        rgb.alpha = 0.9;

        return this.configService.rgb2hex(this.changeAlphaToColor(rgb));
    });

    public lighter2MainColor: Signal<string> = computed((): string => {
        const rgb: ColorComponents = this.configService.hex2rgb(
            this.mainColor(),
        );

        rgb.alpha = 0.1;

        return this.configService.rgb2hex(this.changeAlphaToColor(rgb));
    });

    private changeAlphaToColor(rgb: ColorComponents): ColorComponents {
        const bgRgb: ColorComponents = this.configService.hex2rgb(
            this.configService.backgroundColor(),
        );

        // combine main color with background color
        let combinedAlpha: number = (1 - rgb.alpha) * bgRgb.alpha + rgb.alpha;

        if (combinedAlpha === 0) {
            // Preventing division by zero
            combinedAlpha = Number.EPSILON;
        }

        rgb.r =
            ((1 - rgb.alpha) * bgRgb.alpha * bgRgb.r + rgb.alpha * rgb.r) /
            combinedAlpha;
        rgb.g =
            ((1 - rgb.alpha) * bgRgb.alpha * bgRgb.g + rgb.alpha * rgb.g) /
            combinedAlpha;
        rgb.b =
            ((1 - rgb.alpha) * bgRgb.alpha * bgRgb.b + rgb.alpha * rgb.b) /
            combinedAlpha;
        rgb.alpha = combinedAlpha;

        // change rgba color to rgb (simplified background alpha to #FFFFFF for easier computation)
        const alpha: number = 1 - rgb.alpha;
        const clampedAlpha: number = Math.max(0, Math.min(1, alpha));
        rgb.r = Math.round((rgb.alpha * (rgb.r / 255) + clampedAlpha) * 255);
        rgb.g = Math.round((rgb.alpha * (rgb.g / 255) + clampedAlpha) * 255);
        rgb.b = Math.round((rgb.alpha * (rgb.b / 255) + clampedAlpha) * 255);
        rgb.alpha = 1;

        return rgb;
    }
}
