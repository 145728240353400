import { NgOptimizedImage } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    input,
    InputSignal,
    ResourceRef,
    Signal,
} from '@angular/core';

import { TenantResponse } from '../../../domain/api-result';
import { ConfigService } from '../../../services/config.service';
import { LoadingComponent } from '../loading/loading.component';

@Component({
    selector: 'app-aside-company-data',
    standalone: true,
    imports: [NgOptimizedImage, LoadingComponent],
    templateUrl: './aside-company-data.component.html',
    styleUrl: './aside-company-data.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideCompanyDataComponent {
    public tenantResource: InputSignal<
        ResourceRef<TenantResponse | undefined> | undefined
    > = input.required();
    public isInSidebar: InputSignal<boolean> = input(false);

    protected companyName: Signal<string> = computed((): string => {
        return this.tenantResource()?.value()?.name ?? '';
    });

    constructor(protected readonly configService: ConfigService) {}
}
