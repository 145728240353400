import {
    computed,
    Injectable,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StepsService {
    public calculationStep: WritableSignal<number> = signal(1);

    public displayStep1: Signal<boolean> = computed((): boolean => {
        return 1 === this.calculationStep();
    });

    public displayStep2: Signal<boolean> = computed((): boolean => {
        return 2 === this.calculationStep();
    });

    public displayStep3: Signal<boolean> = computed((): boolean => {
        return (
            3 === this.calculationStep() ||
            4 === this.calculationStep() ||
            5 === this.calculationStep() ||
            6 === this.calculationStep() ||
            7 === this.calculationStep()
        );
    });

    public displayStep4: Signal<boolean> = computed((): boolean => {
        return (
            4 === this.calculationStep() ||
            5 === this.calculationStep() ||
            6 === this.calculationStep() ||
            7 === this.calculationStep()
        );
    });

    public displayStep5: Signal<boolean> = computed((): boolean => {
        return (
            5 === this.calculationStep() ||
            6 === this.calculationStep() ||
            7 === this.calculationStep()
        );
    });

    public displayStep6: Signal<boolean> = computed((): boolean => {
        return 6 === this.calculationStep();
    });

    public displayStep7: Signal<boolean> = computed((): boolean => {
        return 7 === this.calculationStep();
    });
}
