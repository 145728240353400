import {
    Component,
    effect,
    OnDestroy,
    ResourceLoaderParams,
    ResourceRef,
} from '@angular/core';
import { rxResource, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { SafeParseReturnType } from 'zod';

import { TenantResponse } from '../../domain/api-result';
import {
    LocalStorageCalculation,
    LocalStorageCalculationSchema,
} from '../../domain/local-storage';
import { SunnaApiService } from '../../services/sunna-api.service';
import { ReturnAsideComponent } from './components/return-aside/return-aside.component';

@Component({
    selector: 'app-return',
    standalone: true,
    imports: [ReturnAsideComponent],
    templateUrl: './return.component.html',
    styleUrl: './return.component.scss',
})
export class ReturnComponent implements OnDestroy {
    protected tenantResource: ResourceRef<TenantResponse | undefined> =
        rxResource({
            request: toSignal(this.activatedRoute.params),
            loader: (
                params: ResourceLoaderParams<Params | undefined>,
            ): Observable<TenantResponse> => {
                return this.sunnaApiService.tenant(
                    params.request?.['companySlug'],
                );
            },
        });

    private subscription: Subscription = new Subscription();
    private calculationId: string | undefined;

    constructor(
        private readonly router: Router,
        private readonly sunnaApiService: SunnaApiService,
        private readonly activatedRoute: ActivatedRoute,
    ) {
        effect((): void => {
            const tenant: TenantResponse | undefined =
                this.tenantResource.value();

            if (this.tenantResource.isLoading()) {
                return;
            }

            if (undefined === tenant) {
                this.router
                    .navigate(['404'], {
                        skipLocationChange: true,
                        replaceUrl: false,
                    })
                    .then((): void => {});
                return;
            }

            const jsonPreviousCalculation: string | null = localStorage.getItem(
                'previousCalculation',
            );

            if (null === jsonPreviousCalculation) {
                this.onStartNewCalculation();
                return;
            }

            const localStorageCalculation: LocalStorageCalculation | undefined =
                JSON.parse(jsonPreviousCalculation);

            const parse: SafeParseReturnType<unknown, unknown> =
                LocalStorageCalculationSchema.safeParse(
                    localStorageCalculation,
                );

            if (!parse.success) {
                this.onStartNewCalculation();
                return;
            }

            this.calculationId = localStorageCalculation?.calculationId;
        });
    }

    public onReturnToCalculation(): void {
        this.router
            .navigate([
                this.tenantResource.value()?.slug,
                'calculation',
                this.calculationId,
            ])
            .then((): void => {});
    }

    public onStartNewCalculation(): void {
        localStorage.removeItem('previousCalculation');

        this.router
            .navigate([this.tenantResource.value()?.slug])
            .then((): void => {});
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
