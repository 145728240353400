<main>
    <app-results-aside
        [isReadonly]="isReadonly()"
        [isLoading]="isLoading()"
        [apiResultSolarPanelConfiguration]="resultResource.value()?.solarPanelConfiguration"
        [selectedSolarPanel]="selectedSolarPanel()?.data"
        [selectedInverter]="selectedInverter()?.data"
        [tenantResource]="tenantResource"
    />
    <div class="content">
        <div class="title-container">
            <div>
                <p class="title">{{ 'Your Photovoltaic System' | translate }}</p>
                <p class="sub-title" [innerHTML]="'Our recommendation for address is an powerkW system, which will cover your current energy bill and reduce your monthly costs by reduction currency.' | translate:{
                        address: '<span class=\'sentry-mask\'>' + address() + '</span>',
                        power: systemPowerDcKwh() | number: '1.2-2',
                        reduction: (oldBillMonthly() - newBillMonthly()) | currency:'':'',
                        currency: tenantResource.value()?.currency_code ?? 'USD'
                }">
                </p>
            </div>

            @if (calculationResource.value()?.id) {
                <div class="with-button">
                    <button id="share-button" class="btn btn-hollow {{ calculationResource.value()?.id }}" (click)="share()">
                        <span>{{ 'Share the offer' | translate }}</span>
                        <em class="icon icon-share"></em>
                    </button>
                </div>
            }
        </div>

        <app-box [displayFooter]="false">
            <ng-container title>
                {{ 'Your summary' | translate }}
            </ng-container>

            <ng-container content>
                <app-concept
                    [isReadonly]="isReadonly()"
                    [isLoading]="isLoading()"
                    [result]="resultResource.value()"
                    [tenant]="tenantResource.value()"
                    (changePanelsCountEvent)="changePanelsCount($event)"
                />
            </ng-container>
        </app-box>

        <app-box [displayFooter]="false">
            <ng-container title>
                {{ 'Component selection' | translate }}
            </ng-container>

            <ng-container content>
                <app-loading [isLoading]="isLoading()" [showContent]="true">
                    <app-components-select
                        [isReadonly]="isReadonly()"
                        [(solarPanel)]="selectedSolarPanel"
                        [(inverter)]="selectedInverter"
                        [modules]="modulesResource.value()"
                        [tenant]="tenantResource.value()"
                    />
                </app-loading>
            </ng-container>
        </app-box>

        @if (tenantResource.value()?.has_battery_in_storage) {
            <app-box [displayFooter]="false">
                <ng-container title>
                    {{ 'Plan energy storage' | translate }}
                </ng-container>

                <ng-container content>
                    <app-loading [isLoading]="isLoading()" [showContent]="true">
                        <app-energy-storage
                            [isReadonly]="isReadonly()"
                            [(battery)]="selectedBattery"
                            [(batteryEnabled)]="batteryEnabled"
                            [(batteryCount)]="batteryCount"
                            [(energyStorageSliderValue)]="energyStorageSliderValue"
                            [(chargingCarSliderValue)]="chargingCarSliderValue"
                            [modules]="modulesResource.value()"
                            [result]="resultResource.value()"
                        />
                    </app-loading>
                </ng-container>
            </app-box>
        }

        <app-box [displayFooter]="false">
            <ng-container title>
                {{ 'Introductory offer' | translate }}
            </ng-container>

            <ng-container content>
                <app-offer
                    [isReadonly]="isReadonly()"
                    [isLoading]="isLoading()"
                    [tenant]="tenantResource.value()"
                />
            </ng-container>
        </app-box>
    </div>
</main>

<app-address-error-modal [tenantResource]="tenantResource" [calculationId]="calculationId()"/>
<app-phone-modal (submitEvent)="submitModal($event)" />
<app-calculation-share-modal />
