<div class="aside-content">
    <app-aside-company-data [tenantResource]="undefined" />

    <div>
        <p>{{ 'Welcome back' | translate }}</p>
        <p class="smaller">{{ 'What do you want to do next...?' | translate }}</p>

        <app-loading [isLoading]="!loaded">
            <div class="buttons">
                <button (click)="onReturnToCalculation()" class="btn btn-primary btn-with-icon left sentry-mask" type="submit"><em class="icon icon-chevron-left-full"></em>{{ 'Back to yours' | translate }} {{ power }}kWp {{ 'system' | translate }}, {{ address }}</button>
                <button (click)="onStartNewCalculation()" class="btn btn-hollow btn-with-icon normal-icon" type="submit">{{ 'New concept and offer' | translate }} <em class="icon icon-chevron-right-full"></em></button>
            </div>
        </app-loading>
    </div>
</div>

<app-aside-footer />
