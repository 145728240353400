import {
    ChangeDetectionStrategy,
    Component,
    effect,
    input,
    InputSignal,
    OnDestroy,
    ResourceRef,
    signal,
    WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Subscription } from 'rxjs';

import { TenantResponse } from '../../../domain/api-result';
import {
    ModalData,
    ModalService,
} from '../../../page/results/services/modal.service';
import { ErrorService } from '../../../services/error.service';
import { SunnaApiService } from '../../../services/sunna-api.service';
import { ModalComponent } from '../modal/modal.component';
import { PhoneFormComponent } from '../phone-form/phone-form.component';

/**
 * Displays missing address modal with form.
 * To display this modal, add this component to
 * your view and call `ModalService.set{ModalType}(true)`
 */
@Component({
    selector: 'app-address-error-modal',
    standalone: true,
    imports: [ModalComponent, PhoneFormComponent, TranslateModule],
    templateUrl: './address-error-modal.component.html',
    styleUrl: './address-error-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressErrorModalComponent implements OnDestroy {
    public calculationId: InputSignal<string | undefined> = input.required();
    public tenantResource: InputSignal<
        ResourceRef<TenantResponse | undefined>
    > = input.required();

    protected isOpen: WritableSignal<boolean> = signal(false);
    protected submitted: WritableSignal<boolean> = signal(false);

    private sunnaSlug: string = '';
    private errorMessage: string =
        'Unexpected error occurred. Contact administrator.';

    private subscription: Subscription = new Subscription();

    constructor(
        private readonly modalService: ModalService,
        private readonly sunnaApiService: SunnaApiService,
        private readonly errorService: ErrorService,
        private readonly translateService: TranslateService,
        private readonly router: Router,
        readonly activatedRoute: ActivatedRoute,
    ) {
        effect((): void => {
            const tenant: TenantResponse | undefined =
                this.tenantResource().value();

            if (undefined === tenant && !this.tenantResource().isLoading()) {
                this.errorService.addError(this.errorMessage);
                return;
            }

            this.sunnaSlug = tenant?.slug as string;
        });

        lastValueFrom(
            this.translateService.get(
                'Unexpected error occurred. Contact administrator.',
            ),
        ).then((value: string): void => {
            this.errorMessage = value;
        });

        this.subscription.add(
            this.modalService.addressErrorModal.subscribe(
                (value: ModalData): void => {
                    this.isOpen.set(value.open);
                },
            ),
        );
    }

    protected close(): void {
        this.modalService.setAddressErrorModalState(false);
        this.router.navigate([this.sunnaSlug]).then((): void => {});
    }

    protected formSubmit(phone: string): void {
        const calculationId: string | undefined = this.calculationId();

        if (!calculationId) {
            this.errorService.addError(this.errorMessage);
            return;
        }

        lastValueFrom(
            this.sunnaApiService.saveCalculation(calculationId, {
                phone_number: phone,
                sunna_slug: this.sunnaSlug,
                system_panel_module: null,
                system_inverter_module: null,
                has_battery: false,
                system_battery_module: null,
                system_battery_qty: null,
                financial_type: 'cash',
                consumption_energy_car_charger: 0,
                consumption_energy_storage: 0,
            }),
        )
            .then((): void => {
                this.submitted.set(true);
            })
            .catch((): void => {
                this.errorService.addError(this.errorMessage);
            });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
