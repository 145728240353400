<app-modal [isOpen]="isOpen()" (closeEvent)="close()">
    @if (!submitted()) {
        <div>
            <p class="dialog-title">
                {{ 'we have a problem with the automatic analysis of your roof...' | translate }}
            </p>
            <p>
                <span [innerHTML]="'It\'s possible it\'s in an area we don\'t yet service or the data isn\'t sufficient.' | translate"></span>
                <br>
                <br>
                <span [innerHTML]="'But it\'s not a problem! Give us your phone number and our advisor will prepare an analysis and offer for you free of charge.' | translate"></span>
            </p>

            <app-phone-form
                [submitText]="'I am ordering a free analysis' | translate"
                (formSubmitEvent)="formSubmit($event)"
            />
        </div>
    } @else {
        <div>
            <p class="dialog-title">{{ 'We have accepted your request!' | translate }}</p>
            <p>{{ 'Wait for contact from our advisor.' | translate }}</p>
        </div>
    }
</app-modal>
