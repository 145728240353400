import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AsideCompanyDataComponent } from '../../../aside-company-data/aside-company-data.component';
import { AsideFooterComponent } from '../../../aside-footer/aside-footer.component';

@Component({
    selector: 'app-not-found-aside',
    standalone: true,
    imports: [AsideCompanyDataComponent, AsideFooterComponent, TranslateModule],
    templateUrl: './not-found-aside.component.html',
    styleUrl: './not-found-aside.component.scss',
})
export class NotFoundAsideComponent {}
