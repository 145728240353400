import {
    ChangeDetectionStrategy,
    Component,
    computed,
    input,
    InputSignal,
    ResourceRef,
    Signal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import {
    ApiResultSolarPanelConfiguration,
    TenantResponse,
} from '../../../../domain/api-result';
import { AsideCompanyDataComponent } from '../../../../shared/components/aside-company-data/aside-company-data.component';
import { AsideFooterComponent } from '../../../../shared/components/aside-footer/aside-footer.component';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';
import { BoxComponent } from '../box/box.component';
import { PriceComponent } from '../price/price.component';
import { TabListComponent } from '../tab-list/tab-list.component';

interface ViewModel {
    systemPower: number;
    panelCount: number;
    panelLabel: string;
    inverterLabel: string;
}

@Component({
    selector: 'app-results-aside',
    standalone: true,
    imports: [
        AsideFooterComponent,
        AsideCompanyDataComponent,
        BoxComponent,
        TabListComponent,
        TranslateModule,
        LoadingComponent,
        PriceComponent,
    ],
    templateUrl: './results-aside.component.html',
    styleUrl: './results-aside.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsAsideComponent {
    public tenantResource: InputSignal<
        ResourceRef<TenantResponse | undefined>
    > = input.required();
    public isReadonly: InputSignal<boolean> = input(false);
    public isLoading: InputSignal<boolean> = input(false);
    public apiResultSolarPanelConfiguration: InputSignal<
        ApiResultSolarPanelConfiguration | undefined
    > = input<ApiResultSolarPanelConfiguration | undefined>(undefined);
    public selectedSolarPanel: InputSignal<string | undefined> = input();
    public selectedInverter: InputSignal<string | undefined> = input();

    protected viewModel: Signal<ViewModel> = computed(
        (): ViewModel => ({
            systemPower:
                this.apiResultSolarPanelConfiguration()?.systemPowerDcKwh ?? 0,
            panelCount:
                this.apiResultSolarPanelConfiguration()?.totalPanelCount ?? 0,
            panelLabel: this.selectedSolarPanel() ?? '',
            inverterLabel: this.selectedInverter() ?? '',
        }),
    );
}
