<div class="aside-company-data" [class.in-sidebar]="isInSidebar()">
    <app-loading [isLoading]="tenantResource()?.isLoading() ?? false" [showContent]="true">
        @if (configService.backgroundImage(); as companyLogo) {
            <img [ngSrc]="companyLogo" priority width="108" height="108" [alt]="companyName()">
        } @else {
            <img ngSrc="/assets/company@2x.webp" priority width="108" height="108" [alt]="companyName()">
        }
    </app-loading>

    <app-loading [isLoading]="tenantResource()?.isLoading() ?? false" [showContent]="true">
        <p id="company-name">{{ companyName() }}</p>
    </app-loading>
</div>
