import { NgOptimizedImage } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    effect,
    ElementRef,
    input,
    InputSignal,
    ViewChild,
} from '@angular/core';

import { PanelCoordinates } from '../../../../domain/api-result';

/**
 * Renders panels on provided image.
 * The Provided image is inserted into `canvas`
 * element as background image and then points
 * from `panelCoordinatePoints` are used as
 * corners of each panel (array of `PanelCoordinates` objects)
 *
 * Example usage:
 * @example
 * <app-image-panels
 *     image="https://placehold.co/512x508"
 *     [panelCoordinatePoints]="[{tl:{x:0,y:0},tr:{x:100,y:0},br:{x:100,y:100},bl:{x:0,y:100}}]"
 * />
 */
@Component({
    selector: 'app-image-panels',
    standalone: true,
    imports: [NgOptimizedImage],
    templateUrl: './image-panels.component.html',
    styleUrl: './image-panels.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePanelsComponent implements AfterViewInit {
    public image: InputSignal<string | undefined> = input<string | undefined>(
        undefined,
    );
    public panelCoordinatePoints: InputSignal<PanelCoordinates[]> = input<
        PanelCoordinates[]
    >([]);

    @ViewChild('canvasElementRef', { static: false })
    private canvasElementRef!: ElementRef<HTMLCanvasElement>;

    protected imageWidth: number = 602;
    protected imageHeight: number = 601;
    protected canvasWidth: number = 843;
    protected canvasHeight: number = 724;

    protected ratio: number = 1;
    protected xOffset: number = 0;
    protected yOffset: number = 0;
    protected imageOffset: number = 0;
    protected context: CanvasRenderingContext2D | null = null;
    protected pattern: CanvasPattern | null = null;

    constructor() {
        effect((): void => {
            this.image();
            this.panelCoordinatePoints();

            this.calcTranslate();
            this.placePanels();
        });
    }

    public ngAfterViewInit(): void {
        this.canvasWidth = this.canvasElementRef.nativeElement.offsetWidth;
        this.context = this.canvasElementRef.nativeElement.getContext('2d');

        const hRatio: number = this.canvasWidth / this.imageWidth;
        const vRatio: number = this.canvasHeight / this.imageHeight;
        this.ratio = Math.max(hRatio, vRatio);

        const panelImage: HTMLImageElement = new Image();
        panelImage.onload = (): void => {
            this.loadPattern(panelImage);
        };
        panelImage.src = 'assets/panel.jpg';
    }

    protected loadPattern(panelImage: CanvasImageSource): void {
        if (null === this.context) {
            return;
        }

        this.pattern = this.context.createPattern(panelImage, 'repeat');
    }

    protected calcTransform(data: PanelCoordinates): number {
        const rotationRadians: number = Math.atan(
            (data.tl.x - data.bl.x) / (data.tl.y - data.bl.y),
        );

        return rotationRadians * (180 / Math.PI) * -1;
    }

    protected calcTranslate(): void {
        this.xOffset =
            ((this.imageWidth * this.ratio - this.canvasWidth) / 2) * -1;
        this.yOffset =
            ((this.imageHeight * this.ratio - this.canvasHeight) / 2) * -1;

        let lowestYPosition: number = -Infinity;
        let highestYPosition: number = Infinity;

        this.panelCoordinatePoints().forEach((dp: PanelCoordinates): void => {
            const dpbly: number = dp.bl.y * this.ratio + this.yOffset;
            if (dpbly > lowestYPosition) {
                lowestYPosition = dpbly;
            }

            const dpbry: number = dp.br.y * this.ratio + this.yOffset;
            if (dpbry > lowestYPosition) {
                lowestYPosition = dpbry;
            }

            const dptly: number = dp.tl.y * this.ratio + this.yOffset;
            if (dptly < highestYPosition) {
                highestYPosition = dptly;
            }

            const dptry: number = dp.tr.y * this.ratio + this.yOffset;
            if (dptry < highestYPosition) {
                highestYPosition = dptry;
            }
        });

        // below
        if (lowestYPosition > this.canvasHeight) {
            this.imageOffset = this.yOffset;
            this.yOffset = this.yOffset * 2;
        }

        // above
        if (highestYPosition != null && highestYPosition < 0) {
            this.imageOffset = this.yOffset * -1;
            this.yOffset = 0;
        }
    }

    protected placePanels(): boolean {
        if (null === this.context) {
            return false;
        }

        const matrix: DOMMatrix = new DOMMatrix([1, 0, 0, 1, 0, 0]);

        const context: CanvasRenderingContext2D = this.context;

        this.panelCoordinatePoints().forEach(
            (dataPoints: PanelCoordinates): void => {
                context.beginPath();

                context.moveTo(
                    dataPoints.tl.x * this.ratio + this.xOffset,
                    dataPoints.tl.y * this.ratio + this.yOffset,
                );
                context.lineTo(
                    dataPoints.tr.x * this.ratio + this.xOffset,
                    dataPoints.tr.y * this.ratio + this.yOffset,
                );
                context.lineTo(
                    dataPoints.br.x * this.ratio + this.xOffset,
                    dataPoints.br.y * this.ratio + this.yOffset,
                );
                context.lineTo(
                    dataPoints.bl.x * this.ratio + this.xOffset,
                    dataPoints.bl.y * this.ratio + this.yOffset,
                );

                const rotation: number = this.calcTransform(dataPoints);

                let scale: number = 0.01;
                if (/msie\s|Firefox\//i.test(window.navigator.userAgent)) {
                    scale = 0.1;
                }

                this.pattern?.setTransform(
                    matrix.rotate(rotation).scale(scale),
                );

                context.fillStyle = this.pattern ?? '#1056a4';
                context.fill();

                context.closePath();
            },
        );

        return true;
    }

    protected onImageLoaded(e: Event): boolean {
        if (null === this.context) {
            return false;
        }

        this.context.drawImage(
            e.target as HTMLImageElement,
            ((this.imageWidth * this.ratio - this.canvasWidth) / 2) * -1,
            ((this.imageHeight * this.ratio - this.canvasHeight) / 2) * -1 +
                this.imageOffset,
            this.imageWidth * this.ratio,
            this.imageHeight * this.ratio,
        );
        this.placePanels();

        return true;
    }
}
