<div class="aside-content">
    <app-aside-company-data [tenantResource]="undefined" />

    <div class="calculation-progress-container">
        <div class="loading">
            <em class="icon icon-loading"></em>
        </div>
        <div class="title">
            <p [@fade]="1 === calculationStep() ? 'in' : 'out'">{{ 'Roof edge detection' | translate }}</p>
            <p [@fade]="2 === calculationStep() ? 'in' : 'out'">{{ 'Checking sunlight' | translate }}</p>
            <p [@fade]="3 === calculationStep() ? 'in' : 'out'">{{ 'Obstacle and shadow detection' | translate }}</p>
            <p [@fade]="4 === calculationStep() ? 'in' : 'out'">{{ 'Taking into account the distance from the edge' | translate }}</p>
            <p [@fade]="5 === calculationStep() ? 'in' : 'out'">{{ 'Laying out panels on roof' | translate }}</p>
            <p [@fade]="6 === calculationStep() ? 'in' : 'out'">{{ 'Calculation of energy yields' | translate }}</p>
            <p [@fade]="7 === calculationStep() ? 'in' : 'out'">{{ 'Creating the most optimal proposal' | translate }}</p>
            <span>{{ calculationStep() }} / 7</span>
        </div>
        <ul>
            <li [@fade]="1 < calculationStep() ? 'in' : 'out'">{{ 'Roof edge detection' | translate }}</li>
            <li [@fade]="2 < calculationStep() ? 'in' : 'out'">{{ 'Checking sunlight' | translate }}</li>
            <li [@fade]="3 < calculationStep() ? 'in' : 'out'">{{ 'Obstacle and shadow detection' | translate }}</li>
            <li [@fade]="4 < calculationStep() ? 'in' : 'out'">{{ 'Taking into account the distance from the edge' | translate }}</li>
            <li [@fade]="5 < calculationStep() ? 'in' : 'out'">{{ 'Laying out panels on roof' | translate }}</li>
            <li [@fade]="6 < calculationStep() ? 'in' : 'out'">{{ 'Calculation of energy yields' | translate }}</li>
        </ul>
    </div>
</div>

<app-aside-footer />
