<h4>{{ 'The last step before we analyze your roof and prepare a concept.' | translate }}</h4>
<p>{{ 'We will also send a copy of the results to your email address.' | translate }}</p>

<form [formGroup]="step2Form" (ngSubmit)="formSubmit()">
    <div class="form-group" [class.have-errors]="firstName?.invalid && !firstName?.untouched">
        <label for="first-name">{{ 'First Name' | translate }}</label>
        <input class="sentry-mask" type="text" placeholder="Joe" formControlName="firstName" id="first-name">
        @if (firstName?.invalid && !firstName?.untouched) {
            <div class="form-errors">
                @if (firstName?.errors?.['required']) {
                    <div>{{ 'First Name is required.' | translate }}</div>
                }
            </div>
        }
    </div>
    <div class="form-group" [class.have-errors]="lastName?.invalid && !lastName?.untouched">
        <label for="last-name">{{ 'Last Name' | translate }}</label>
        <input class="sentry-mask" type="text" placeholder="Doe" formControlName="lastName" id="last-name">
        @if (lastName?.invalid && !lastName?.untouched) {
            <div class="form-errors">
                @if (lastName?.errors?.['required']) {
                    <div>{{ 'Last Name is required.' | translate }}</div>
                }
            </div>
        }
    </div>
    <div class="form-group" [class.have-errors]="email?.invalid && !email?.untouched">
        <label for="email">{{ 'Email' | translate }}</label>
        <input class="sentry-mask" type="email" placeholder="joe@doe.com" formControlName="email" id="email">
        @if (email?.invalid && !email?.untouched) {
            <div class="form-errors">
                @if (email?.errors?.['required']) {
                    <div>{{ 'Email Name is required.' | translate }}</div>
                }
                @if (email?.errors?.['email']) {
                    <div>{{ 'Not a valid email.' | translate }}</div>
                }
            </div>
        }
    </div>
    <div class="form-group checkbox-form-group" [class.have-errors]="agreement?.invalid && !agreement?.untouched">
        <label>
            <input type="checkbox" formControlName="agreement">
            <span>{{ 'I consent to the processing of personal data and sending the offer.' | translate }}</span>
        </label>
        @if (agreement?.invalid && !agreement?.untouched) {
            <div class="form-errors">
                @if (agreement?.errors?.['required']) {
                    <div>{{ 'Agreement is required.' | translate }}</div>
                }
            </div>
        }
    </div>
    <div class="flex">
        <div class="step">{{ 'Step' | translate }} 2/2</div>
        <app-loading [isLoading]="isSubmitted()" [showContent]="true">
            <div class="buttons">
                <button class="btn btn-with-icon" (click)="back()" type="button"><em class="icon icon-chevron-left"></em> {{ 'Property & Electric Bill' | translate }}</button>
                <button class="btn btn-primary btn-with-icon" type="submit">{{ 'View Quote' | translate }} <em class="icon icon-chevron-right-full"></em></button>
            </div>
        </app-loading>
    </div>
</form>
