import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { Module, ModulesResponse } from '../../../domain/api-result';
import { SunnaApiService } from '../../../services/sunna-api.service';

@Injectable({
    providedIn: 'root',
})
export class ModulesService {
    constructor(private readonly sunnaApiService: SunnaApiService) {}

    private async fetchModulesOnPage(
        sunnaSlug: string,
        page: number = 1,
        accumulatedModules: Module[] = [],
    ): Promise<Module[]> {
        const modulesResponse: ModulesResponse = await lastValueFrom(
            this.sunnaApiService.modules(sunnaSlug, page),
        );

        const updatedModules: Module[] = [
            ...accumulatedModules,
            ...modulesResponse.results,
        ];

        if (null !== modulesResponse.next) {
            return this.fetchModulesOnPage(sunnaSlug, page + 1, updatedModules);
        }

        return updatedModules;
    }

    public async fetchModules(sunnaSlug: string): Promise<Module[]> {
        return this.fetchModulesOnPage(sunnaSlug);
    }
}
