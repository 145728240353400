import { baseEnvironment, Environment } from './environment.base';

export const environment: Environment = {
    ...baseEnvironment,
    PRODUCTION: true,
    RELEASE: '1.50.1',
    API_URL: 'https://sunna-api-production.easysolar-app.com/',
    SENTRY_DSN:
        'https://272392cca115dd4cc2e2bca4c5db890c@o4506310544588800.ingest.us.sentry.io/4507249181917184',
    DEFAULT_COMPANY_SLUG: 'your-company-name',
};
