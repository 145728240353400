import {
    ChangeDetectionStrategy,
    Component,
    effect,
    OnDestroy,
    ResourceLoaderParams,
    ResourceRef,
    signal,
    WritableSignal,
} from '@angular/core';
import { rxResource, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { fadeDisplayInOutAnimation } from '../../domain/animations';
import { TenantResponse } from '../../domain/api-result';
import { LocalStorageCalculation } from '../../domain/local-storage';
import { ConfigService } from '../../services/config.service';
import { LanguageService } from '../../services/language.service';
import { SunnaApiService } from '../../services/sunna-api.service';
import { FormAsideComponent } from './components/form-aside/form-aside.component';
import {
    Step1Component,
    Step1FormValues,
} from './components/step-1/step-1.component';
import {
    Step2Component,
    Step2FormValues,
} from './components/step-2/step-2.component';

export interface FormValues extends Step1FormValues, Step2FormValues {}

@Component({
    selector: 'app-form',
    standalone: true,
    imports: [Step1Component, Step2Component, FormAsideComponent],
    templateUrl: './form.component.html',
    styleUrl: './form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fadeDisplayInOutAnimation,
})
export class FormComponent implements OnDestroy {
    private mainFormValues: FormValues = {
        address: '',
        bill: 6.93,
        email: '',
        equipment: 1,
        firstName: '',
        lastName: '',
        latitude: 10,
        longitude: 11,
    };

    protected step: WritableSignal<1 | 2> = signal(1);

    protected tenantResource: ResourceRef<TenantResponse | undefined> =
        rxResource({
            request: toSignal(this.activatedRoute.params),
            loader: (
                params: ResourceLoaderParams<Params | undefined>,
            ): Observable<TenantResponse> => {
                return this.sunnaApiService.tenant(
                    params.request?.['companySlug'],
                );
            },
        });

    private subscription: Subscription = new Subscription();

    constructor(
        private readonly router: Router,
        private readonly sunnaApiService: SunnaApiService,
        private readonly activatedRoute: ActivatedRoute,
        readonly languageService: LanguageService,
        readonly configService: ConfigService,
    ) {
        effect((): void => {
            const tenant: TenantResponse | undefined =
                this.tenantResource.value();

            if (this.tenantResource.isLoading()) {
                return;
            }

            if (undefined === tenant) {
                this.router
                    .navigate(['404'], {
                        skipLocationChange: true,
                        replaceUrl: false,
                    })
                    .then((): void => {});
                return;
            }

            languageService.setLanguageOptions(tenant);
            configService.setColorsFromTenant(tenant);

            const previousCalculation: LocalStorageCalculation = JSON.parse(
                localStorage.getItem('previousCalculation') ?? '{}',
            );

            if (previousCalculation.company !== tenant.slug) {
                return;
            }

            this.router.navigate([tenant.slug, 'return']).then((): void => {});
        });
    }

    protected step1Submit(formValues: Step1FormValues): void {
        this.mainFormValues.address = formValues.address;
        this.mainFormValues.latitude = formValues.latitude;
        this.mainFormValues.longitude = formValues.longitude;
        this.mainFormValues.bill = formValues.bill;
        this.mainFormValues.equipment = formValues.equipment;

        this.step.set(2);
    }

    protected async step2Submit(formValues: Step2FormValues): Promise<void> {
        this.mainFormValues.firstName = formValues.firstName;
        this.mainFormValues.lastName = formValues.lastName;
        this.mainFormValues.email = formValues.email;

        await this.router
            .navigate([this.tenantResource.value()?.slug, 'calculating'], {
                queryParams: {
                    address: this.mainFormValues.address,
                    latitude: this.mainFormValues.latitude,
                    longitude: this.mainFormValues.longitude,
                    bill: this.mainFormValues.bill,
                    equipment: this.mainFormValues.equipment,
                    firstName: this.mainFormValues.firstName,
                    lastName: this.mainFormValues.lastName,
                    email: this.mainFormValues.email,
                },
            })
            .then((): void => {});
    }

    protected step2Back(): void {
        this.step.set(1);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
