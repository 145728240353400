import {
    ChangeDetectionStrategy,
    Component,
    computed,
    OnDestroy,
    output,
    OutputEmitterRef,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { TenantResponse } from '../../../../domain/api-result';
import { ModalComponent } from '../../../../shared/components/modal/modal.component';
import { PhoneFormComponent } from '../../../../shared/components/phone-form/phone-form.component';
import { ModalData, ModalService } from '../../services/modal.service';

@Component({
    selector: 'app-phone-modal',
    standalone: true,
    imports: [ModalComponent, PhoneFormComponent, TranslateModule],
    templateUrl: './phone-modal.component.html',
    styleUrl: './phone-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneModalComponent implements OnDestroy {
    protected isOpen: WritableSignal<boolean> = signal(false);
    protected data: WritableSignal<Record<string, unknown> | undefined> =
        signal(undefined);

    private subscription: Subscription = new Subscription();

    public submitEvent: OutputEmitterRef<string> = output<string>();

    protected callToActionButtonLabel: Signal<string | null> = computed(
        (): string | null => {
            const buttonLabel: string =
                (this.data()?.['tenant'] as TenantResponse | undefined)
                    ?.preferences.call_to_action_button_label ?? '';

            if ('' === buttonLabel) {
                return null;
            }

            return buttonLabel;
        },
    );

    constructor(private readonly modalService: ModalService) {
        this.subscription.add(
            this.modalService.phoneModal.subscribe((value: ModalData): void => {
                this.data.set(value.data as Record<string, unknown>);
                this.isOpen.set(value.open);
            }),
        );
    }

    protected close(): void {
        this.modalService.setPhoneModalState(false);
    }

    protected formSubmit(phone: string): void {
        this.close();

        this.submitEvent.emit(phone);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
