<div class="inputs">
    <div class="form-group">
        <label for="solar-panel">{{ 'Solar Panel' | translate }}</label>
        @if (isReadonly()) {
            <ng-container>{{ solarPanel()?.data }}</ng-container>
        } @else {
            <select2
                id="solar-panel"
                required
                [value]="solarPanelValue()"
                [data]="solarPanelSelect2Data()"
                (update)="solarPanelUpdate($event)"
            />
        }
    </div>
    <div class="form-group">
        <label for="inverter">{{ 'Inverter' | translate }}</label>
        @if (isReadonly()) {
            <ng-container>{{ inverter()?.data }}</ng-container>
        } @else {
            <select2
                id="inverter"
                required
                [value]="inverterValue()"
                [data]="inverterSelect2Data()"
                (update)="inverterUpdate($event)"
            />
        }
    </div>
</div>
