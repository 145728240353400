import { IMAGE_CONFIG } from '@angular/common';
import {
    HttpClient,
    provideHttpClient,
    withInterceptors,
    withInterceptorsFromDi,
} from '@angular/common/http';
import {
    APP_INITIALIZER,
    ApplicationConfig,
    ErrorHandler,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';

import { AppComponent } from './app/app.component';
import { retryInterceptorFn } from './app/interceptors/retry.interceptor';
import { CalculationsComponent } from './app/page/calculations/calculations.component';
import { FormComponent } from './app/page/form/form.component';
import { ResultsComponent } from './app/page/results/results.component';
import { ReturnComponent } from './app/page/return/return.component';
import { NotFoundComponent } from './app/shared/components/not-found/not-found.component';
import { environment } from './environments/environment';

if (environment.PRODUCTION) {
    const serverErrorsRegex: RegExp =
        /401 Unauthorized|402 Payment Required|404 Not Found|405 Method Not Allowed/im;

    Sentry.init({
        dsn: environment.SENTRY_DSN,
        release: environment.RELEASE,
        enabled: true,
        environment: 'production',
        ignoreErrors: [serverErrorsRegex],
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.browserApiErrorsIntegration(),
            Sentry.browserProfilingIntegration(),
            Sentry.replayIntegration({
                mask: ['.sentry-mask'],
                maskAllText: false,
                blockAllMedia: false,
            }),
            Sentry.extraErrorDataIntegration(),
            Sentry.httpClientIntegration(),
        ],
        tracePropagationTargets: [environment.API_URL],
        profilesSampleRate: 0.2,
        replaysOnErrorSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        sendDefaultPii: true,
        tracesSampleRate: 0.2,
    });
}

export class CustomLoader extends TranslateHttpLoader {
    constructor(http: HttpClient) {
        super(http, '/assets/translations/', '.json');
    }
}

const routes: Routes = [
    { path: '404', component: NotFoundComponent },
    {
        path: ':companySlug',
        component: FormComponent,
    },
    {
        path: ':companySlug/calculating',
        component: CalculationsComponent,
    },
    {
        path: ':companySlug/results',
        component: ResultsComponent,
    },
    {
        path: ':companySlug/return',
        component: ReturnComponent,
    },
    {
        path: ':companySlug/calculation/:calculationId',
        component: ResultsComponent,
    },
    { path: '**', redirectTo: environment.DEFAULT_COMPANY_SLUG },
];

const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideRouter(routes),
        {
            provide: IMAGE_CONFIG,
            useValue: {
                placeholderResolution: 40,
            },
        },
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomLoader,
                deps: [HttpClient],
            },
        }).providers!,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (): (() => void) => (): void => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        provideHttpClient(
            withInterceptorsFromDi(),
            withInterceptors([retryInterceptorFn]),
        ),
    ],
};

bootstrapApplication(AppComponent, appConfig).catch((err: Error): void =>
    console.error(err),
);
