import {
    ChangeDetectionStrategy,
    Component,
    input,
    InputSignal,
    model,
    ModelSignal,
    output,
    OutputEmitterRef,
} from '@angular/core';

@Component({
    selector: 'app-count-input',
    standalone: true,
    imports: [],
    templateUrl: './count-input.component.html',
    styleUrl: './count-input.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountInputComponent {
    public value: ModelSignal<number> = model(1);

    public stopChange: InputSignal<boolean> = input(false);
    public allowIncrement: InputSignal<boolean> = input(true);
    public allowDecrement: InputSignal<boolean> = input(true);
    public isReadonly: InputSignal<boolean> = input(false);

    public changeCount: OutputEmitterRef<number> = output<number>();

    protected increment(): void {
        if (this.isReadonly() || !this.allowIncrement()) {
            return;
        }

        this.propagate(this.value() + 1);
    }

    protected decrement(): void {
        if (this.isReadonly() || !this.allowDecrement()) {
            return;
        }

        if (1 === this.value()) {
            return;
        }

        this.propagate(this.value() - 1);
    }

    private propagate(value: number): void {
        if (!this.stopChange()) {
            this.value.set(value);
        }

        this.changeCount.emit(value);
    }
}
