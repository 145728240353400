<div class="offer-content">
    @if (tenant()?.preferences?.financial_enabled ?? false) {
        <app-tab-list/>
    }

    <app-loading [isLoading]="isLoading()" [showContent]="true">
        <app-inner-box id="your-system">
            <ng-container title>{{ 'Your system' | translate }}</ng-container>
            <ng-container sub-title>{{ prices().systemPrice | currency:'':'' }} <span class="smaller">{{ tenant()?.currency_code ?? 'USD' }}</span></ng-container>
            @if ('' !== (tenant()?.preferences?.proposal_description ?? '').trim()) {
                <ng-container content>
                    <div [innerHTML]="tenant()?.preferences?.proposal_description"></div>
                </ng-container>
            }
        </app-inner-box>
    </app-loading>
    <app-loading [isLoading]="isLoading()" [showContent]="true">
        <app-inner-box>
            <ng-container title>{{ 'Warranty' | translate }}</ng-container>
            <ng-container sub-title>{{ 'Included in the price' | translate }}</ng-container>
            @if ('' !== (tenant()?.preferences?.warranty_description ?? '').trim()) {
                <ng-container content>
                    <div [innerHTML]="tenant()?.preferences?.warranty_description"></div>
                </ng-container>
            }
        </app-inner-box>
    </app-loading>
    <app-loading [isLoading]="isLoading()" [showContent]="true">
        @if (tenant()?.preferences?.donations_enabled) {
            <app-inner-box id="grant">
                <ng-container title>{{ 'Grant' | translate }}</ng-container>
                <ng-container sub-title>{{ prices().grantPrice | currency:'':'' }} <span class="smaller">{{ tenant()?.currency_code ?? 'USD' }}</span></ng-container>

                @if ('' !== (tenant()?.preferences?.donations_description ?? '').trim()) {
                    <ng-container content>
                        <div [innerHTML]="tenant()?.preferences?.donations_description"></div>
                    </ng-container>
                }
            </app-inner-box>
        }
    </app-loading>
    <div class="offer-price">
        <app-price
            [isReadonly]="isReadonly()"
            [alignRight]="true"
            [tenant]="tenant()"
            [isLoading]="isLoading()"
        />
    </div>
</div>
