import {
    ChangeDetectionStrategy,
    Component,
    input,
    InputSignal,
    ResourceRef,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { TenantResponse } from '../../../../domain/api-result';
import { AsideCompanyDataComponent } from '../../../../shared/components/aside-company-data/aside-company-data.component';
import { AsideFooterComponent } from '../../../../shared/components/aside-footer/aside-footer.component';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';

@Component({
    selector: 'app-form-aside',
    standalone: true,
    imports: [
        AsideCompanyDataComponent,
        AsideFooterComponent,
        TranslateModule,
        LoadingComponent,
    ],
    templateUrl: './form-aside.component.html',
    styleUrl: './form-aside.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormAsideComponent {
    public tenantResource: InputSignal<
        ResourceRef<TenantResponse | undefined>
    > = input.required();
}
